import { css } from "@linaria/core";
import type { ComponentProps, PropsWithChildren, ReactHTML } from "react";
import { createElement } from "react";

import Icon from "./Icon";

const dropdownItemCss = css`
  min-height: 24px;
  min-width: 144px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

type HtmlElement = keyof ReactHTML;

type Props<T extends HtmlElement> = {
  element?: T;
  icon?: string;
} & ComponentProps<ReactHTML[T]>;

const DEFAULT_ELEMENT = "div";

const DropdownItem = <T extends HtmlElement>({
  children,
  element = DEFAULT_ELEMENT as T,
  icon,
  ...rest
}: PropsWithChildren<Props<T>>): JSX.Element =>
  createElement(
    element,
    { className: dropdownItemCss, ...rest },
    <>
      {icon && <Icon src={icon} />}
      {children}
    </>
  );

export default DropdownItem;
