import type { CollapseProps } from "antd";

import rightArrowIcon from "images/primary/right-arrow-sm.svg";

import Icon from "./Icon";

const ExpandIcon: CollapseProps["expandIcon"] = ({ isActive }) => (
  <Icon
    alt="Expand"
    src={rightArrowIcon}
    style={{
      transition: "transform .2s",
      transform: `rotate(${isActive ? 90 : 0}deg)`
    }}
  />
);

export default ExpandIcon;
