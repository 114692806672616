import { ThemeConfig } from "antd";

import colors from "./colors";
import seedtime from "./seedtime";

export const SCREEN_SM_MAX = 767;

const theme: ThemeConfig = {
  components: {
    Breadcrumb: {
      linkHoverColor: colors.linkHover,
      linkColor: colors.primary
    },
    Checkbox: {
      colorBgContainer: colors["page-background"],
      fontSize: 13,
      marginXS: 0
    },
    Dropdown: {
      borderRadiusLG: 4,
      controlPaddingHorizontal: 8,
      fontSize: 13,
      marginXXS: 0,
      paddingXXS: 0
    },
    Modal: {
      headerBg: colors["page-background"],
      paddingContentHorizontalLG: 0,
      paddingMD: 0
    },
    Popover: {
      paddingSM: 8,
      fontSize: 13
    },
    Tabs: {
      itemSelectedColor: "#332b25"
    },
    Tooltip: {
      fontSize: 13
    }
  },
  token: {
    colorLink: colors.primary,
    colorPrimary: colors.primary,
    colorSuccessText: colors.primary,
    colorTextSecondary: colors["mid-grey"],
    fontFamily: seedtime["text-font"],
    fontSize: 16,
    screenSMMax: SCREEN_SM_MAX
  }
};

export default theme;
