import { Dropdown, Flex } from "antd";

import sortIcon from "images/sort.svg";
import addDropdownDividers from "helpers/addDropdownDividers";

import DropdownItem from "../DropdownItem";

import { SortKeys, SortOption } from "./types";

const DEFAULT_SORT_OPTIONS: SortOption[] = [
  {
    key: SortKeys.Index,
    name: "By Crop"
  },
  {
    key: SortKeys.LayerGroup,
    name: "By Group"
  },
  {
    key: SortKeys.PlantingLocation,
    name: "By Planting Location"
  },
  {
    key: SortKeys.SeedingDate,
    name: "Seeding Date"
  },
  {
    key: SortKeys.InGroundDate,
    name: "In Ground Date"
  },
  {
    key: SortKeys.HarvestingDate,
    name: "Harvesting Date"
  }
];

const items = addDropdownDividers(
  DEFAULT_SORT_OPTIONS.map(({ key, name }) => ({
    key,
    label: <DropdownItem element="span">{name}</DropdownItem>
  }))
);

type Props = {
  onChange: (key: SortKeys) => void;
};

const TimelineSortPresentation = ({ onChange }: Props) => {
  const handleMenuCLick = (params: { key: string }) => onChange(params.key as SortKeys);

  return (
    <Dropdown menu={{ items, onClick: handleMenuCLick }}>
      <Flex align="center" gap={4} component="button" data-testid="task-filter-button">
        <img alt="Sort" src={sortIcon} />
        <span>Sort</span>
      </Flex>
    </Dropdown>
  );
};

export { TimelineSortPresentation };
