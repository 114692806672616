import { styled } from "@linaria/react";
const SIZES_MAP = {
  medium: "18px",
  large: "24px"
};

const DEFAULT_SIZE = "medium";

type Props = { size?: keyof typeof SIZES_MAP };

const Icon = styled.img<Props>`
  aspect-ratio: 1;
  width: ${({ size }) => SIZES_MAP[size || DEFAULT_SIZE]};
`;

export default Icon;
